<template>
  <div v-if="loginShowBox">
    <div class="menu_box" v-if="menuShowBox">
      <div
        class="van-sticky"
        :class="[
          menuShow ? 'van-sticky1' : opacityStyle.showhash ? 'van-sticky2' : '',
          drawer ? 'van-sticky3' : '',
        ]"
        :style="{
          background: 'rgba(255,255,255,' + opacityStyle.opacity + ')',
        }"
      >
        <template v-if="!menuShow">
          <div class="nav_title">
            <div class="province">{{ province }}</div>
            <!-- <div class="gkt" >
              <div class="juli">
                <div class="f8">距离</div>
                <div class="f8">高考</div>
              </div>
              <div class="f12 num">1</div>
              <div class="f12">天</div>
            </div> -->
          </div>
          <el-input
            placeholder="院校 / 专业 / 职业 / 课程 / 资讯"
            prefix-icon="el-icon-search"
            v-model="search"
            class="search"
          >
          </el-input>
        </template>
        <template v-else>
          <i class="el-icon-arrow-left f24" @click="handleBack"></i>
          <div class="title_name">
            {{ title }}
          </div>
        </template>
        <div class="nav" :class="menuShow ? 'open' : ''">
          <div
            class="top-right-menu transition"
            :class="drawer ? 'open' : ''"
            @click="handleMenu"
          >
            <div class="line top"></div>
            <div class="line middle"></div>
            <div class="line bottom"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="other" v-else>
      <div class="nav" :class="drawer ? 'open' : ''">
        <div
          class="top-right-menu transition"
          :class="drawer ? 'open' : ''"
          @click="handleMenu"
        >
          <div class="line top"></div>
          <div class="line middle"></div>
          <div class="line bottom"></div>
        </div>
      </div>
    </div>

    <menu-center
      :showPopup.sync="drawer"
      @closePopup="closePopup"
    ></menu-center>
    <el-drawer :visible.sync="areaDrawer" :with-header="false" size="100%">
      <div class="area_box">
        <div class="area_title">
          <i class="el-icon-arrow-left" @click="handleArea"></i>
          <span>选择省份</span>
          <p></p>
        </div>
        <ul class="area_ul">
          <li
            v-for="(item, index) in areaOptions"
            :key="index"
            @click="setProvince(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import menuCenter from '@/components/Header/menuCenter';
import { mapState } from 'vuex';

export default {
  components: { menuCenter },
  name: 'Header',
  props: {
    loginShowBox: {
      type: Boolean,
      default: false,
    },
    menuShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    menuShowBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      areaDrawer: false,
      search: '',
      province: '',
      provinceList: [], //省市
      opacityStyle: {
        // 设置初始状态是透明的
        opacity: 0,
        showhash: false,
        top: 0,
      },
      areaOptions: [],
      areaOptionsNew: [],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      // isAuthed: state => (state.userinfo ? true : false),
      // notify: state => state.notify,
      // favorates: state => state.favorates,
      // histories: state => state.histories
    }),
  },
  watch: {
    $route: function(newVal, oldVal) {
      console.log(oldVal, '=====$route', newVal);
      if (newVal.path == '/') {
        let userInfo = JSON.parse(localStorage.getItem('userInfo')) || null;
        let localProvinceInfo =
          JSON.parse(localStorage.getItem('localProvinceInfo')) || null;
        console.log('nav======menu', userInfo);
        // if (userInfo && userInfo.province) {
        //   this.province = userInfo.province;
        //   const data =
        //     this.areaOptions &&
        //     this.areaOptions.find((item) => {
        //       if (item.name == this.province) return item;
        //     });
        //   console.log('====data', data);
        //   localStorage.removeItem('storeCurrentProvince');
        //   localStorage.removeItem('localProvince');
        //   localStorage.removeItem('localProvinceInfo');
        //   this.setProvince(data);
        // }
        if (userInfo) {
          localStorage.removeItem('storeCurrentProvince');
          localStorage.removeItem('localProvince');
          localStorage.removeItem('localProvinceInfo');

          if (userInfo.province) {
            this.province = userInfo.province;
            const data =
              this.areaOptions &&
              this.areaOptions.find((item) => {
                if (item.name == this.province) return item;
              });
            console.log('====data', data);
            this.setProvince(data);
          } else {
            this.$toast('请选择省市');
            setTimeout(() => {
              this.areaDrawer = !this.areaDrawer;
              // this.setProvince(this.areaOptions[0]);
            }, 2000);
          }
        } else {
          if (!(localProvinceInfo && localProvinceInfo.name)) {
            this.$toast('请选择省市');
            setTimeout(() => {
              this.areaDrawer = !this.areaDrawer;
              // this.setProvince(this.areaOptions[0]);
            }, 2000);
          } else {
            this.province = localProvinceInfo.name;
          }
        }
      }
    },
  },
  async mounted() {
    await this.getProvinceList();
    window.addEventListener('scroll', this.handleScroll);

    if (this.$route.path == '/') {
      let userInfo = JSON.parse(localStorage.getItem('userInfo')) || null;
      let localProvinceInfo =
        JSON.parse(localStorage.getItem('localProvinceInfo')) || null;
      if (userInfo) {
        localStorage.removeItem('storeCurrentProvince');
        localStorage.removeItem('localProvince');
        localStorage.removeItem('localProvinceInfo');

        if (userInfo.province) {
          this.province = userInfo.province;
          const data =
            this.areaOptions &&
            this.areaOptions.find((item) => {
              if (item.name == this.province) return item;
            });
          console.log('====data', data);
          this.setProvince(data);
        } else {
          this.$toast('请选择省市');
          setTimeout(() => {
            this.areaDrawer = !this.areaDrawer;
            // this.setProvince(this.areaOptions[0]);
          }, 2000);
        }
      } else {
        console.log(
          localProvinceInfo && localProvinceInfo.name,
          '===userInfouserInfo',
          userInfo
        );
        if (!localProvinceInfo) {
          this.$toast('请选择省市');
          setTimeout(() => {
            this.areaDrawer = !this.areaDrawer;
            // this.setProvince(this.areaOptions[0]);
          }, 2000);
        } else {
          this.province = localProvinceInfo.name;
        }
      }
    }
  },
  async created() {},
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    closePopup() {
      this.drawer = false;
    },
    handleScroll() {
      //获取当前页面的滚动条纵坐标位置      网页被卷去的高
      const top = document.documentElement.scrollTop || document.body.scrollTop;
      this.opacityStyle.top = top;
      if (top > 5) {
        let opacity = top / 140;
        //就是看这个我们设置的那个属性是不是小于1的没达到条件之前就是半透明状态
        // opacity = opacity > 1 ? 1 : opacity;
        this.opacityStyle.opacity = opacity;
      } else {
        this.opacityStyle.opacity = 0;
      }
      if (top > 30) {
        this.opacityStyle.showhash = true;
      } else {
        this.opacityStyle.showhash = false;
      }
    },
    //导航
    handleMenu() {
      this.drawer = !this.drawer;
    },
    //地址
    handleArea() {
      this.areaDrawer = !this.areaDrawer;
    },
    //area
    async getProvinceList() {
      const apiUrl = '/api/province/';
      try {
        const res = await this.$axios.get(apiUrl);
        this.areaOptions = res.data.results.results;
        console.log('areaOptions', this.areaOptions);
      } catch (error) {
        console.log(`question error: ${error}`);
      }
      const apiUrl1 = '/php/province.list';
      try {
        const res = await this.$axios.get(apiUrl1);
        if (res.data.ret === 0) {
          this.provinceList = res.data.data;
          // this.userInfo = res.data.data[0].name;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //handleProvince
    async setProvince(item) {
      this.province = item?.name;
      let provideData = this.provinceList.find((ele) => {
        if (ele.name == this.province) return ele;
      });

      localStorage.setItem('localProvince', item.name);
      localStorage.setItem('localProvinceInfo', JSON.stringify(provideData));
      this.$store.commit('UPDATA_PROVINCE', item.name);
      if (this.areaOptions.length > 0) {
        this.areaOptions.forEach((itemNew) => {
          if (itemNew.name == item.name) {
            this.$store.commit('UPDATA_PROVINCE_INFO', itemNew);
          }
        });
      }

      this.$store.commit('UPDATE_IS_NEW', item.is_new);
      let userInfo = JSON.parse(localStorage.getItem('userInfo')) || null;
      console.log(userInfo && !userInfo.province, '===www', userInfo);
      localStorage.setItem('storeCurrentProvince', JSON.stringify(item));
      if (userInfo && !userInfo.province) {
        const apiUrl = '/api/user/update_province/';
        const res = await this.$axios.post(apiUrl, {
          province: item.name,
          is_new: item.is_new,
        });
        console.log(provideData, '选择省份', res);
        if (res.data.errno === 0) {
          this.getProvinceSet(provideData);
          // this.$store.dispatch('getUserInfo');
        }
      }
      this.areaDrawer = false;
    },
    async getProvinceSet(data) {
      const apiUrl = '/php/user.province.set';

      const res = await this.$axios.post(apiUrl, {
        provinceId: data.id,
      });
      if (res.data.ret === 0) {
        localStorage.removeItem('userInfo');
        const res = await this.$axios.get('/api/user/info/');

        const user = await this.$axios.get('/php/user.info');

        if (res.data.errno == 0) {
          localStorage.setItem('userInfo', JSON.stringify(res.data.results));
          // this.$store.dispatch('getUserInfo');
        }
      }
    },
    async getScoreConfig() {
      const apiUrl = '/php/score.config.get';
      const res = await this.$axios.get(apiUrl);
      if (res.data.ret === 0) {
        this.provinceList = res.data.data;
        this.province = res.data.data[0].name;
      }
    },
    //back
    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu_box {
  // position: fixed;
  // width:100%;
  // z-index: 10;
  // position: absolute;
}
.van-sticky {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 38px;
  padding: 10px 15px;
  width: 100%;
  z-index: 1;

  .title_name {
    font-size: 16px;
    color: #212121;
  }
  .province {
    white-space: nowrap;
  }
  .nav_title {
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    margin-right: 10px;
    .gkt {
      font-weight: 400;
      line-height: 16px;
      height: 16px;
      display: flex;
      align-items: flex-start;
      .juli {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }

      .juli .f8 {
        font-size: 16px;
        transform: scale(0.5);
        transform-origin: 0 0;
        line-height: 8px;
        width: 34px;
        white-space: nowrap;
      }

      .num {
        font-family: Bebas-Regular, Bebas;
        margin-right: 1px;
        font-weight: 600;
        line-height: 12px;
      }
    }
  }
  .search {
    // width: 75%;
    color: #c8c9cc;
    padding: 0px;
    .el-input__inner {
      height: 38px !important;
      border-radius: 20px;
    }
    /deep/ .el-input__inner {
      padding: 10px 15px 10px 30px;
      border-radius: 20px;
    }
  }
  .nav {
    width: 50px;
    position: relative;
    z-index: 9999;
  }
  .top-right-menu {
    margin-top: 1px;
    width: 24px;
    height: 24px;
    display: inline-block;
  }

  .top-right-menu .line {
    background: #fff;
    width: 21px;
    height: 2px;
    border-radius: 1px;
    margin-top: 5px;
    margin-left: 2px;
  }

  .transition div {
    transition: 0.3s;
  }

  .top-right-menu {
    position: absolute;
    right: 0px;
    top: -12px;
    z-index: 99999;
  }

  .top-right-menu.open .top {
    -webkit-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }

  .top-right-menu.open .middle {
    opacity: 0;
  }

  .top-right-menu.open .bottom {
    -webkit-transform: translateY(-6px) rotate(-45deg);
    transform: translateY(-6px) rotate(-45deg);
  }
  .open {
    .line {
      background-color: #9b9b9b !important;
    }
  }
}
.van-sticky1 {
  padding: 13.5px 15px;
  background: #fff !important;
  border-bottom: 1px solid #ddd;
}
.van-sticky2.van-sticky {
  .top-right-menu .line {
    background-color: #000;
  }
  .nav_title {
    color: #000;
  }
}
.van-sticky3 {
  position: absolute;
  z-index: auto;
}
.other {
  .nav {
    width: 100%;
    position: relative;
    z-index: 9999;
  }
  .top-right-menu {
    margin-top: 1px;
    width: 24px;
    height: 24px;
    display: inline-block;
  }

  .top-right-menu .line {
    background: #fff;
    width: 21px;
    height: 2px;
    border-radius: 1px;
    margin-top: 5px;
    margin-left: 2px;
  }

  .transition div {
    transition: 0.3s;
  }

  .top-right-menu {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 99999;
  }

  .top-right-menu.open .top {
    -webkit-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }

  .top-right-menu.open .middle {
    opacity: 0;
  }

  .top-right-menu.open .bottom {
    -webkit-transform: translateY(-6px) rotate(-45deg);
    transform: translateY(-6px) rotate(-45deg);
  }
  .open {
    .line {
      background-color: #fff !important;
    }
  }
}
.area_box {
  .area_title {
    display: flex;
    margin: 10px 16px;
    line-height: 38px;
    height: 38px;
    color: #212121;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 800;
    .el-icon-arrow-left {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .area_ul {
    padding: 0 15px;
    height: 90vh;
    overflow: auto;
    li {
      border-bottom: 1px solid #f5f5f5;
      font-size: 15px;
      color: #212121;
      padding: 12px 0px;
      font-weight: 400;
    }
  }
}
</style>
