<template>
  <div class="user_center_component popup_wrap">
    <el-drawer
      :visible.sync="popShow"
      :with-header="false"
      size="70%"
      @close="closePopup"
    >
      <div class="user_head">
        <div class="user_avatar" v-if="userInfo">
          <img v-if="userInfo.head_img" :src="userInfo.head_img" alt />
          <img v-else src="../../assets/imgs/icon_head.jpg" alt="" />
          <span class="user_nickname">{{ userInfo.phone }}</span>
        </div>
        <div class="login" v-else>
          <span @click="signup()">注册</span>
          <span class="line">|</span>
          <span @click="signin()">登录</span>
        </div>
      </div>
      <div class="user_menu">
        <ul class="menu_list">
          <li @click="handleHome('首页', '/')">
            <div>
              <span>首页</span>
            </div>
          </li>
          <li @click="handleHome('模拟填报', '/accounts/createScore')">
            <div>
              <span>模拟填报</span>
            </div>
          </li>
          <li @click="handleHome('我的志愿表', '/myTablesList')">
            <div>
              <span>我的志愿表</span>
            </div>
          </li>
          <li @click="showLang" :class="{ parent_menu: showSubNav }">
            <div>
              <span>测评系统</span>
            </div>
            <van-icon v-if="showSubNav" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </li>
          <li class="sub_menu" v-if="showSubNav">
            <div @click="cePing('zhiye')">
              职业测评
            </div>
            <div @click="cePing('course')">
              新高考选科测评
            </div>
            <div @click="cePing('xuexi')">
              学习状态测评
            </div>
          </li>
          <li @click="handleHome('关于我们', '/contact/aboutUs')">
            <div>
              <span>关于我们</span>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="userInfo" class="login login_out">
        <span @click="loginOut()">退出登录</span>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'menuCenter',
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popShow: false,
      userInfo: null,
      showSubNav: false,
      scoreHistory: null,
    };
  },
  watch: {
    showPopup: function() {
      this.popShow = this.showPopup;
    },
  },
  async mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))|| null;
    console.log('==kuai', this.userInfo);
    if(this.userInfo){
      this.getScoreHistory();
    }
  },
  methods: {
    async getScoreHistory() {
      const apiUrl = '/php/user.score.get'; //'php/score.config.get';

      const res = await this.$axios.get(apiUrl);
      if (res.data.ret === 0) {
        this.scoreHistory = res.data.data;
      }
    },
    closePopup() {
      this.$emit('closePopup', false);
    },
    showLang() {
      this.showSubNav = !this.showSubNav;
    },
    cePing(type) {
      this.closePopup();
      switch (type) {
        case 'zhiye':
          this.$router.push({ path: '/myEvaluations/career' });
          break;
        case 'course':
          this.$router.push({ path: '/myEvaluations/course' });
          break;
        case 'xuexi':
          this.$router.push({ path: '/myEvaluations/learning' });
          break;
      }
    },
    signin() {
      this.closePopup();
      this.$router.push({ name: 'Signin' });
    },
    signup() {
      this.closePopup();
      this.$router.push({ name: 'Signup' });
    },
    loginOut() {
      this.userInfo=null;
      localStorage.clear();
      sessionStorage.clear();
      this.$cookies.remove('yzy-token');
      localStorage.removeItem('scoreInfoData');
      localStorage.removeItem('localProvince');
      localStorage.removeItem('storeCurrentProvince');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('storeCurrentProvince');
      localStorage.removeItem('localProvince');
      localStorage.removeItem('localProvinceInfo');
      window.location = '/';
      this.closePopup();
    },
    handleHome(name, path) {
      this.closePopup();
      if (name == '模拟填报') {
        if (this.scoreHistory?.score) {
          this.$router.push({
            path: '/accounts/chooseBatch',
            query: {
              grade: this.scoreHistory.score.predict_score,
              course: this.scoreHistory.score.course,
            },
          });
        } else {
          this.$router.push({ path: '/accounts/createScore' });
        }
      } else {
        this.$router.push({ path: path });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.user_center_component {
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
  }
  a {
    color: #910000;
  }
}

.popup_wrap {
  font-size: 0.26rem;
  .van-popup--left {
    width: 75%;
    height: 100%;
    overflow: hidden;
    background: #fff;
  }
  .user_head {
    width: 100%;
    display: flex;
    height: 3rem;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;

    background: url('../../assets/image/head_bg1.png') no-repeat;
    background-size: 100% auto;
    span.line {
      margin: 0 8px;
    }
    .user_nickname {
      font-size: 0.34rem;
      color: #fff;
    }
    .user_avatar {
      display: flex;
      align-items: center;
    }
    img {
      width: 1.4rem;
      height: 1.4rem;
      border-radius: 50%;
      margin-right: 10px;
      border: 1px solid #e8e8e8;
    }
    .login {
      margin-top: 0.24rem;
      color: #fff;
      font-size: 20px;
    }
  }
  .user_menu {
    // height: calc(100vh - 160px);
    overflow-y: scroll;
    ul.menu_list {
      padding: 0.5rem 0;
      li {
        padding: 0.2rem 0.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.14rem;
        > div {
          display: flex;
          align-items: center;
        }
        img {
          width: 0.42rem;
        }
        span {
          margin-left: 14px;
        }
        span.num {
          color: #ee3e3e;
        }
        &.sub_menu {
          background: #f7f7f7;
          margin-bottom: 0;
          display: block;
          div {
            padding: 0.2rem 0.5rem;
          }
          img {
            width: 0.54rem;
            margin-right: 14px;
          }
          span.radio_icon {
            display: inline-block;
            width: 20px;
            border-radius: 50%;
            height: 20px;
            border: 2px solid #444242;
            position: relative;
            padding: 2px;
            margin-right: 6px;
            margin-left: 0;
            i {
              display: inline-block;
              width: 12px;
              border-radius: 50%;
              height: 12px;
              background: #444242;
              position: absolute;
            }
          }
        }
      }
      li.parent_menu {
        margin-bottom: 0;
      }
    }
  }
}
.van-overlay {
  background: rgba(26, 26, 26, 0.5);
}
.login_out {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #323233;
    background-color: #fff;
    border: 1px solid #ebedf0;
    border-radius: 100px;
    padding: 6px 40px;
  }
}
</style>
