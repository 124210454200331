<template>
  <div class="layout_page">
    <Header
      :menuShow="menuShow"
      :title="title"
      :menuShowBox="menuShowBox"
      :loginShowBox="loginShowBox"
    ></Header>
    <router-view />
    <ul class="nav_menu" v-if="navBarShow">
      <li
        v-for="(item, index) in navMenu"
        :key="index"
        :class="{ active_menu: index == activeTab }"
        @click="changeTab(item, index)"
      >
        {{ item.name }}
      </li>
    </ul>
    <!-- <van-tabbar
      v-if="navBarShow"
      v-model="activeTab"
      active-color="#910000"
      inactive-color="#333"
      route
     @change="changeTab"
    >
      <van-tabbar-item v-for="(item, index) in navMenu"
        :key="index">{{ item.name }}</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/components/Header';
export default {
  components: { Header },
  data() {
    return {
      activeTab: 0,
      show: false,
      navBarShow: false,
      menuShow: true,
      menuShowBox: false,
      loginShowBox: false,
      title: '',
      navMenu: [
        {
          name: '首页',
          path: '/',
          id: 1,
        },
        {
          name: '模拟填报',
          path: '/accounts/createScore',
          id: 2,
        },
        {
          name: '测评',
          path: '/assessmentSystem/home',
          id: 3,
        },
        {
          name: '我的',
          path: '/myCenter',
          id: 4,
        },
      ],
      scoreHistory: null,
    };
  },
  computed: {
    ...mapState({
      // userInfo: (state) => state.userinfo,
    }),
  },
  watch: {
    $route: function(newVal, oldVal) {
      this.navBarShow = newVal.meta.showPageNavBar;
      this.loginShowBox = this.$route.meta.showMenuBox;
      let newInfo = JSON.parse(localStorage.getItem('userInfo')) || null;
      console.log('==isMobile', this.isMobile());
      let isMobile = this.isMobile();
      if (!isMobile) {
        // window.location.href = 'https://www.gkbkzc.com';
      }
      if (newVal.meta.needLogin && !newInfo) {
        this.$router.push({ name: 'Signin' });
      } else {
        this.selectNav();
      }
      console.log('===rou', newVal);
      this.title = newVal.meta.title;
      if (newVal.path == '/') {
        this.menuShow = false;
      } else {
        this.menuShow = true;
      }
      // if (this.$route.path == '/home' || this.$route.path == '/signin') {
      //   this.loginShowBox = false;
      // } else {
      //   this.loginShowBox = true;
      // }
      if (newVal.path == '/myCenter') {
        this.menuShowBox = false;
      } else {
        this.menuShowBox = true;
      }
      this.isLogin();
    },
  },
  mounted() {
    this.isLogin();
    let isMobile = this.isMobile();
    if (!isMobile) {
      // window.location.href = 'https://www.gkbkzc.com';
    }
    this.selectNav();
    this.navBarShow = this.$route.meta.showPageNavBar;
    this.loginShowBox = this.$route.meta.showMenuBox;
    this.title = this.$route.meta.title;

    if (this.$route.path == '/') {
      this.menuShow = false;
    } else {
      this.menuShow = true;
    }
    // if (this.$route.path == '/home' || this.$route.path == '/signin') {
    //   this.loginShowBox = false;
    // } else {
    //   this.loginShowBox = true;
    // }
    if (this.$route.path == '/myCenter') {
      this.menuShowBox = false;
    } else {
      this.menuShowBox = true;
    }
  },
  methods: {
    //判断登陆状态
    isLogin() {
      let data = JSON.parse(localStorage.getItem('userInfo')) || null;
      // debugger
      if (data && data.province) {
        this.getScoreHistory();
      }
    },
    //判断是否是手机
    isMobile() {
      var userAgentInfo = navigator.userAgent;

      var mobileAgents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod',
      ];

      var mobile_flag = false;

      //根据userAgent判断是否是手机
      for (var v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
          mobile_flag = true;
          break;
        }
      }
      var screen_width = window.screen.width;
      var screen_height = window.screen.height;

      //根据屏幕分辨率判断是否是手机
      if (screen_width > 325 && screen_height < 750) {
        mobile_flag = true;
      }

      return mobile_flag;
    },
    async getScoreHistory() {
      const apiUrl = '/php/user.score.get'; //'php/score.config.get';
      const res = await this.$axios.get(apiUrl);
      if (res.data.ret === 0) {
        this.scoreHistory = res.data.data;
      }
    },
    selectNav() {
      this.navMenu.forEach((item, index) => {
        if (this.$route.path == item.path) {
          this.activeTab = index;
        }
      });
      // console.log('this.activeTab---',this.activeTab);
    },
    changeTab(item, index) {
      if (index == this.activeTab) {
        return false;
      }
      this.activeTab = index;
      console.log('===changeTab', item);
      if (item.name === '模拟填报') {
        console.log('=========wzr=========');
        console.log('==================',this.scoreHistory);
        // debugger;
        if (this.scoreHistory.score && this.scoreHistory.score.predict_score||this.scoreHistory.score.real_score) {
          this.$router.push({
            path: '/accounts/chooseBatch',
            query: {
              grade: this.scoreHistory.score.predict_score?this.scoreHistory.score.predict_score:this.scoreHistory.score.real_score,
              course: this.scoreHistory.score.course,
            },
          });
        } else {
          this.$router.push({ path: '/accounts/createScore' });
        }
      } else {
        this.$router.push({ path: item.path });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout_page {
  min-height: 100vh;
  ul.nav_menu {
    font-weight: 600;
    font-size: 0.26rem;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    box-sizing: content-box;
    width: 100%;
    height: 50px;
    background-color: #fff;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #e8e8e8;
    .active_menu {
      color: #910000;
    }
  }
}
</style>
